import React, {ReactNode} from 'react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Icon} from '@daedalus/atlas/Icon'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

type FooterProps = {
  children?: ReactNode
  customMessage?: ReactNode
  disabled?: boolean
  onClick: () => void
}

const Footer = styled('div')`
  width: 100%;
  padding: ${cssTheme.layout.spacing.s400} ${cssTheme.layout.spacing.s400};
`

export const GuestPickerFooter = ({
  children,
  customMessage,
  disabled,
  onClick
}: FooterProps) => {
  const buttonMessage = customMessage || (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  )

  return (
    <>
      <Divider />
      <Footer data-id="SearchOverlayFooter">
        <Button
          variant={toggle<Variant[]>(
            'b4f4f3cc-website-restyle-v3',
            'primary',
            toggle<Variant[]>(
              '4728f379-vio-color-restyle',
              'primaryGradient',
              'primary'
            )
          )}
          fullWidth
          type="submit"
          dataId="SearchButton"
          disabled={disabled}
          onClick={onClick}
          iconStart={!customMessage && <Icon name="Search" />}
        >
          <>
            {buttonMessage}
            {children && <> · {children}</>}
          </>
        </Button>
      </Footer>
    </>
  )
}
