import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {DateConfigType} from 'types/State'
import {$PropertyType} from 'utility-types'

import {cssTheme} from '@daedalus/atlas/themes'
import {WEEKENDS_INDEXES} from '@daedalus/core/src/datePicker/config'

interface Props {
  weekdaysShort: $PropertyType<DateConfigType, 'weekdaysShort'>
  firstDayOfWeek: $PropertyType<DateConfigType, 'firstDayOfWeek'>
  shouldHighlightWeekend?: boolean
}

const WeekDaysContainer = styled('div')`
  position: relative; /* ensure z-index sticks */
  padding: ${cssTheme.layout.spacing.s300} ${cssTheme.layout.spacing.s500};
  border-top: 1px solid ${cssTheme.colors.border.neutral.c100};
  box-shadow: ${cssTheme.shadows.action};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: ${cssTheme.layout.spacing.s300};
  width: 100%;
`

const WeekDay = styled('div')<{isWeekend?: boolean}>(
  ({isWeekend = false}) => css`
    display: table-cell;
    text-align: center;
    color: ${cssTheme.colors.content.neutral.c600};
    ${cssTheme.typography.text.bodyS}
    ${isWeekend &&
    css`
      background-color: ${cssTheme.colors.background.neutral.c100};
      border-radius: ${cssTheme.layout.radius.sm};
    `}
  `
)

/* mimics the layout + styling of DatePickerContent to ensure the columns line up */
export const WeekDays = ({
  weekdaysShort,
  firstDayOfWeek,
  shouldHighlightWeekend
}: Props) => {
  // Shift to week start
  const weekEnd = (weekdaysShort || []).slice(0, firstDayOfWeek)
  const weekStart = (weekdaysShort || []).slice(firstDayOfWeek)
  const weekdays = [...weekStart, ...weekEnd]

  // Find indexes of weekend days for the shifted weekdays
  const weekendsIndexes = WEEKENDS_INDEXES.map(index =>
    weekdays.findIndex(weekday => weekday === weekdaysShort[index])
  )
  return (
    <WeekDaysContainer>
      {weekdays.map((day, index) => (
        <WeekDay
          key={day}
          isWeekend={weekendsIndexes.includes(index) && shouldHighlightWeekend}
        >
          {day}
        </WeekDay>
      ))}
    </WeekDaysContainer>
  )
}
