import styled from '@emotion/styled'

import {InputButton} from '@daedalus/atlas/InputButton'

import {formButtonBaseStyles, formButtonComposedStyles} from './styles'

export const FormButton = styled(InputButton)<{
  isFocused?: boolean
  borderRadius?: number
}>(
  ({isFocused, theme}) => !!isFocused && formButtonComposedStyles({theme}),
  ({theme, borderRadius}) => formButtonBaseStyles({theme, borderRadius})
)

export {IconWrapper} from './styles'
