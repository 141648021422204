import React from 'react'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import parseIso from 'date-fns/parseISO'

import {OverlayPage, OverlayPageContent} from '@daedalus/atlas/OverlayPage'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {BaseDatePicker as SearchDatePicker} from '@daedalus/shared/src/search/DatePicker/BaseDatePicker'
import {WeekDays} from '@daedalus/shared/src/search/DatePickerOverlay/WeekDays'
import {DatePickerFooter} from '@daedalus/shared/src/search/SearchOverlayFooter'

import {datePickerMessages} from './DatePicker'

interface FooterPropsType {
  checkIn: string
  checkOut: string
}

const FooterMessage = ({checkIn, checkOut}: FooterPropsType) => {
  if (checkIn && checkOut) {
    const lengthOfStay = differenceInCalendarDays(
      parseIso(checkOut),
      parseIso(checkIn)
    )

    return (
      <FormattedMessageWrapper
        id={datePickerMessages.nights.id}
        defaultMessage={datePickerMessages.nights.defaultMessage}
        values={{count: lengthOfStay}}
      />
    )
  }

  return null
}

interface Props {
  isOpen: boolean
  checkIn: string
  checkOut: string
  firstDayOfWeek: number
  weekdaysShort: string[]
  containerRef: React.RefObject<HTMLDivElement>
  openedDatePickerType: DatePickerType | null
  months: string[]
  earliestCheckInDate: Date
  isLoading?: boolean
  onChange: (checkInDate: string, checkOutDate: string) => void
  onClose: () => void
  onDatePickerOpen: (datePickerType: DatePickerType) => void
  onSubmit: () => void
  onCheckOutSelected?: () => void
}

export const DatePickerOverlay = ({
  isOpen,
  firstDayOfWeek,
  weekdaysShort,
  containerRef,
  openedDatePickerType,
  checkIn,
  checkOut,
  months,
  earliestCheckInDate,
  onChange,
  onClose,
  onDatePickerOpen,
  onSubmit,
  onCheckOutSelected
}: Props) => {
  const isSubmitEnabled = checkIn && checkOut

  return (
    <OverlayPage
      isOpen={isOpen}
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper
            id="hotelCardWidget.datePickerOverlayHeader"
            defaultMessage="When are you going?"
          />
        )
      }}
      onClose={onClose}
      hasHeaderBoxShadow={false}
    >
      <WeekDays firstDayOfWeek={firstDayOfWeek} weekdaysShort={weekdaysShort} />
      <OverlayPageContent ref={containerRef}>
        <SearchDatePicker
          variant="vertical"
          checkIn={checkIn}
          checkOut={checkOut}
          openedDatePickerType={openedDatePickerType}
          firstDayOfWeek={firstDayOfWeek}
          weekdaysShort={weekdaysShort}
          months={months}
          earliestCheckInDate={earliestCheckInDate}
          onChange={onChange}
          onDatePickerOpen={onDatePickerOpen}
          onCheckOutSelected={onCheckOutSelected}
        />
      </OverlayPageContent>
      <DatePickerFooter
        disabled={!isSubmitEnabled}
        onClick={onSubmit}
        checkIn={checkIn}
        checkOut={checkOut}
      >
        {isSubmitEnabled && (
          <FooterMessage checkIn={checkIn} checkOut={checkOut} />
        )}
      </DatePickerFooter>
    </OverlayPage>
  )
}
