import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'

export const HEADER_HEIGHT = 65

export const Header = styled.div`
  background: ${cssTheme.colors.background.neutral.c000};
  box-shadow: none;
  position: relative;
  z-index: 1;
  min-height: ${HEADER_HEIGHT}px;
  display: flex;
  &.--shadow {
    box-shadow: ${cssTheme.shadows.action};
  }
`

export const HeaderWrapper = styled.div<{showCloseButton: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${cssTheme.layout.spacing.s400}
    ${({showCloseButton}) =>
      showCloseButton
        ? cssTheme.layout.spacing.s900
        : cssTheme.layout.spacing.s500};
  min-height: ${HEADER_HEIGHT}px;
`

export const HeaderContent = styled.div`
  flex-grow: 1;
  text-align: center;
  ${cssTheme.typography.text.titleM};
  color: ${cssTheme.colors.content.neutral.c950};
`

export const OverlayBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const OverlayContent = styled.div`
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const OverlayFooter = styled.div`
  box-shadow: ${cssTheme.shadows.fixedReverse};
`
