import React from 'react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const LegendWrapper = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: ${cssTheme.layout.spacing.s200};
  align-items: center;
`

const LegendBox = styled.div`
  padding-top: ${cssTheme.layout.spacing.s100};
  padding-bottom: ${cssTheme.layout.spacing.s100};
  &:before {
    content: '';
    display: block;
    height: ${cssTheme.layout.spacing.s400};
    width: ${cssTheme.layout.spacing.s400};
    border-radius: ${cssTheme.layout.radius.sm};
    background-color: ${cssTheme.colors.background.success.c400};
  }
`

export const Legend = () => {
  return (
    <LegendWrapper>
      <LegendBox />
      <Text variant="bodyXS" colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="cheapestNightlyPrice"
          defaultMessage="Cheapest nightly price"
        />
      </Text>
    </LegendWrapper>
  )
}
