import {SearchQuery} from '../../types/partnersApi/types'

export const getSearchQueryFromProps = ({
  placeId,
  placeName,
  iataCode,
  hotelIds,
  hotelNames,
  country,
  city
}: Partial<{
  placeId: string
  placeName: string
  iataCode: string
  hotelIds: string[]
  hotelNames: string[]
  country: string
  city: string
}>): SearchQuery => {
  if (placeId) {
    return {placeId}
  }
  if (placeName) {
    return {
      place: {
        name: placeName,
        country
      }
    }
  }
  if (hotelIds?.length) {
    return {hotelIds}
  }
  if (hotelNames?.length) {
    return {
      hotelNames: {
        names: hotelNames,
        country,
        city
      }
    }
  }
  if (iataCode) {
    return {iataCode}
  }

  return {}
}

export const getPropsFromSearch = ({
  query
}: {
  query: SearchQuery
}): Partial<{
  iataCode: string
  placeId: string
  placeName: string
  hotelIds: string[]
  hotelNames: string[]
  country: string
  city: string
}> => {
  if (query.placeId) {
    return {placeId: query.placeId}
  }
  if (query.place?.name) {
    return {
      placeName: query.place.name,
      country: query.place.country
    }
  }
  if (query.hotelIds?.length) {
    return {hotelIds: query.hotelIds}
  }
  if (query.hotelNames?.names?.length) {
    return {
      hotelNames: query.hotelNames.names,
      country: query.hotelNames.country,
      city: query.hotelNames.city
    }
  }
  if (query.iataCode) {
    return {iataCode: query.iataCode}
  }

  return {}
}
