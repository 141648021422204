import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemePropType, ThemeType} from 'types/Theme'

import {InputButtonStyledContainer} from '@daedalus/atlas/InputButton'
import {getLinariaClassName} from '@daedalus/atlas/utils/getLinariaClassName'

export const IconWrapper = styled('div')``

// TODO: Remove getLinariaClassName when migrating to linaria

const formButtonHoverStyles = ({theme}: ThemePropType) => css`
  .${getLinariaClassName(InputButtonStyledContainer)} {
    border-color: ${theme.colors.border.neutral.c500};
  }
`

export const formButtonFocusBorderStyles = ({theme}: ThemePropType) => css`
  border-color: ${theme.colors.border.interactive.c500} !important;
  box-shadow: 0px 0px 0px 1px ${theme.colors.border.interactive.c500} !important;
`

export const formButtonFocusIconStyles = ({theme}: ThemePropType) => css`
  color: ${theme.colors.content.interactive.c500} !important;
`
const formButtonFocusStyles = ({theme}: ThemePropType) => css`
  .${getLinariaClassName(InputButtonStyledContainer)} {
    ${formButtonFocusBorderStyles({theme})}
  }
  ${IconWrapper} {
    ${formButtonFocusIconStyles({theme})}
  }
`

export const formButtonBaseStyles = ({theme}: ThemePropType) => css`
  .${getLinariaClassName(InputButtonStyledContainer)} {
    border: 1px solid ${theme.colors.border.neutral.c200};
  }
  ${IconWrapper} {
    color: ${theme.colors.content.neutral.c600};
  }

  :hover {
    border-radius: 10px;
    ${formButtonHoverStyles({theme})}
  }
  :focus {
    ${formButtonFocusStyles({theme})}
  }
`

export const formButtonActiveStyles = ({theme}: {theme: ThemeType}) => css`
  ${formButtonFocusStyles({theme})}
`

export const FormButtonWrapper = styled('div')(
  () => css`
    position: relative;
  `
)

export const ButtonContent = styled.div(
  () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
)
