import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {cssTheme} from '@daedalus/atlas/themes'
import {DatePickerVariant} from '@daedalus/core/src/datePicker/types'

export const HEADER_HEIGHT = 58
export const DAY_SIZE = 44

export const HORIZONTAL_PICKER_MIN_WIDTH = 600
export const HORIZONTAL_PICKER_MAX_WIDTH = 802

interface BaseProps {
  variant: DatePickerVariant
  isSingleMonth?: boolean
  singleDaySelected?: boolean
}

const baseStyles = ({variant, isSingleMonth}: BaseProps) => {
  const commonStyles = css`
    position: relative;
    z-index: 0;
    .wrapper {
      height: 100%;
    }
    .month-wrapper {
      flex: 1 1 0%;
    }
  `

  if (variant === 'vertical') {
    return css`
      width: 100%;
      height: 100%;
      padding: 0 ${cssTheme.layout.spacing.s200};
      padding-bottom: ${cssTheme.layout.spacing.s500};
      ${commonStyles}
    `
  }

  return css`
    width: ${isSingleMonth ? '100%' : `${HORIZONTAL_PICKER_MAX_WIDTH}px`};
    margin-bottom: ${cssTheme.layout.spacing.s200};
    ${commonStyles}
  `
}

const monthsWrapperStyles = ({variant, isSingleMonth}: BaseProps) => {
  if (variant === 'vertical') {
    return css`
      .months-wrapper {
        user-select: none;
        width: 100%;
        border: ${cssTheme.layout.spacing.s300} solid
          ${cssTheme.colors.transparent};
      }
    `
  }
  if (isSingleMonth) return ''
  return css`
    .months-wrapper {
      display: flex;
      flex: 1;
      &:before {
        content: '';
        width: 1px;
        top: ${HEADER_HEIGHT}px;
        height: calc(100% - ${HEADER_HEIGHT}px);
        background-color: ${cssTheme.colors.border.neutral.c100};
        position: absolute;
        left: 50%;
      }
    }
  `
}

const monthCaptionStyles = ({variant, isSingleMonth}: BaseProps) => {
  if (variant !== 'vertical') {
    return css`
      .caption-wrapper {
        position: relative;
        border-bottom: ${isSingleMonth ? 0 : 1}px solid
          ${cssTheme.colors.border.neutral.c100};
      }
    `
  }
}

const navButtonsStyles = ({variant, isSingleMonth}: BaseProps) => {
  if (variant !== 'vertical') {
    return css`
      .nav-button {
        &.nav-button-next {
          right: 0;
        }
        &.nav-button-previous {
          left: 0;
        }
        position: absolute;
        top: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        outline: none;
        font: inherit;
        background: transparent;
        padding: 0
          ${isSingleMonth
            ? cssTheme.layout.spacing.s600
            : cssTheme.layout.spacing.s700};
        height: ${HEADER_HEIGHT}px;
        cursor: pointer;
        color: ${cssTheme.colors.content.interactive.c500};
        &:disabled {
          color: ${cssTheme.colors.content.neutral.c600};
          cursor: not-allowed;
        }
        /* @mirror */
      }
    `
  }
}

const weekDaysStyles = ({variant}: BaseProps) => {
  if (variant === 'vertical') {
    return css`
      .calendar-week-days-wrapper {
        display: none;
      }
    `
  }
}

const monthDaysStyles = ({variant, isSingleMonth}: BaseProps) => {
  return css`
    .calendar-wrapper {
      width: 100%;
      table-layout: fixed;
      border-spacing: ${cssTheme.layout.spacing.s200};
      padding: 0 ${variant === 'horizontal' && !isSingleMonth ? 17 : 0}px;
    }
  `
}

const dayCellStyles = ({variant}: BaseProps) => {
  return css`
    .day-cell {
      border: none;
      background-color: transparent;
      ${cssTheme.typography.text.bodyS}
      display: table-cell;
      position: relative;
      text-align: center;
      cursor: pointer;
      vertical-align: middle;
      padding: 0;
      outline: none;
      border-radius: ${cssTheme.layout.radius.lg};
      height: ${DAY_SIZE}px;
      width: ${variant === 'horizontal' ? `${DAY_SIZE}px` : '100%'};
      color: ${cssTheme.colors.content.neutral.c950};
      &.today {
        color: ${toggle(
          '4728f379-vio-color-restyle',
          cssTheme.colors.content.interactive.c500,
          cssTheme.colors.content.interactive.c800
        )};
      }
    }
  `
}

const weekendsStyles = () => {
  return css`
    .weekends {
      background: ${cssTheme.colors.background.neutral.c050};
    }
  `
}

const disabledDaysStyles = () => {
  return css`
    .disabled {
      cursor: default;
      color: ${cssTheme.colors.content.neutral.c300};
      .loading {
        display: none;
      }
    }
  `
}

const selectedDaysStyles = () => {
  return css`
    .selected {
      background: ${cssTheme.colors.background.interactive.c100};
      color: ${cssTheme.colors.content.interactive.c500};
      border-radius: 0;

      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 4px;
        top: 0;
        background: ${cssTheme.colors.background.interactive.c100};
      }

      &:before {
        left: -4px;
      }

      &:after {
        right: -4px;
      }
      &.start {
        &:before {
          display: none;
        }
      }
      &.end {
        &:after {
          display: none;
        }
      }
    }
  `
}

const startRangeDayStyles = ({singleDaySelected}: BaseProps) => {
  return css`
    .start {
      ${singleDaySelected
        ? css`
            border-radius: ${cssTheme.layout.radius.lg};
            &:after {
              display: none;
            }
          `
        : css`
            border-radius: ${cssTheme.layout.radius.lg} 0 0
              ${cssTheme.layout.radius.lg};
          `}
      .day-inner {
        &:after {
          right: -4px;
          border-left: 4px solid
            ${toggle(
              '4728f379-vio-color-restyle',
              cssTheme.colors.background.interactive.c500,
              cssTheme.colors.background.interactive.c800
            )};
        }
      }
    }
  `
}

const endRangeDayStyles = () => {
  return css`
    .end {
      border-radius: 0 ${cssTheme.layout.radius.lg} ${cssTheme.layout.radius.lg}
        0;
      &:after {
        display: none;
      }

      .day-inner {
        &:after {
          left: -4px;
          border-right: 4px solid
            ${toggle(
              '4728f379-vio-color-restyle',
              cssTheme.colors.background.interactive.c500,
              cssTheme.colors.background.interactive.c800
            )};
        }
      }
    }
  `
}

const startEndRangeDayStyles = ({variant}: BaseProps) => {
  const width = variant === 'vertical' ? '100%' : `${DAY_SIZE}px`
  return css`
    .startEnd {
      z-index: 2;
      padding: 0;

      .day-inner {
        background: ${toggle(
          '4728f379-vio-color-restyle',
          cssTheme.colors.background.interactive.c500,
          cssTheme.colors.background.interactive.c800
        )};
        color: ${cssTheme.colors.content.neutral.c000};
        border-radius: ${cssTheme.layout.radius.lg};
        z-index: 1;
        position: relative;

        width: ${width};
        height: ${DAY_SIZE}px;
        line-height: ${DAY_SIZE}px;

        &:hover {
          background: ${toggle(
            '4728f379-vio-color-restyle',
            cssTheme.colors.background.interactive.c500,
            cssTheme.colors.background.interactive.c800
          )};
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          top: 7px;
          z-index: 1;
          background: transparent;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
        }
      }
    }
  `
}

export const BaseStyledDatePicker = styled.div<BaseProps>(
  baseStyles,
  navButtonsStyles,
  monthsWrapperStyles,
  monthCaptionStyles,
  weekDaysStyles,
  monthDaysStyles,
  dayCellStyles,
  disabledDaysStyles
)

export const DatePickerElement = styled(BaseStyledDatePicker)<BaseProps>(
  weekendsStyles,
  selectedDaysStyles,
  startEndRangeDayStyles,
  startRangeDayStyles,
  endRangeDayStyles
)
