import React from 'react'
import styled from '@emotion/styled'

import {Card} from '@daedalus/atlas/Card'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {UniqueRoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'
import {GuestPicker} from '@daedalus/shared/src/search/GuestPicker'

const ElementWrapper = styled(Card)`
  width: 290px;
`

interface WidgetGuestPickerProp {
  guestsState: UniqueRoomSplitType[]
  onGuestsChange: (roomSplit: UniqueRoomSplitType[]) => void
}

export const WidgetGuestPicker = ({
  guestsState,
  onGuestsChange
}: WidgetGuestPickerProp) => {
  return (
    <ElementWrapper borderRadius="lg">
      <ContentWrapper padding="s500" paddingTop="s400">
        <GuestPicker
          customTitle={
            <FormattedMessageWrapper
              id="guestPicker.title"
              defaultMessage="Guests"
            />
          }
          isCompactLayout
          roomsSplit={guestsState}
          updateRoomsSplit={onGuestsChange}
        />
      </ContentWrapper>
    </ElementWrapper>
  )
}
