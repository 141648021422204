import React, {useCallback, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {InputButton} from '@daedalus/atlas/InputButton'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {UniqueRoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'

import {WidgetGuestPicker} from '../WidgetGuestPicker'
import {
  ButtonContent,
  formButtonActiveStyles,
  formButtonBaseStyles,
  FormButtonWrapper,
  IconWrapper
} from './styles'
interface PropTypes {
  guests: UniqueRoomSplitType[]
  onSubmit: (rooms: UniqueRoomSplitType[]) => void
  onClose?: () => void
  onChange?: () => void
  totalValueOfGuests: number
}

const FormButton = styled(InputButton)<{
  isActive?: boolean
}>(
  ({isActive, theme}) =>
    (isActive && formButtonActiveStyles({theme})) ||
    formButtonBaseStyles({theme})
)

export const GuestPickerPopover = ({
  guests,
  onClose,
  onSubmit,
  onChange,
  totalValueOfGuests
}: PropTypes) => {
  const {isMobile} = useDeviceLayout()
  const {formatMessage} = useIntl()

  const guestPickerRef = useRef<HTMLDivElement>(null)
  const [guestsState, setGuestsState] = useState(guests)
  const [isGuestPickerOpen, setIsGuestPickerOpen] = useState(false)

  const handleOpenGuestPicker = () => {
    setIsGuestPickerOpen(!isGuestPickerOpen)
  }

  const onGuestsChange = useCallback(
    (guests: UniqueRoomSplitType[]) => {
      setGuestsState(guests)
    },
    [onChange]
  )

  const handleSubmit = useCallback(() => {
    onSubmit(guestsState)
    setIsGuestPickerOpen(false)
  }, [onSubmit, guestsState])

  const onOutsideClick = () => {
    onClose?.()

    setGuestsState(guests)
    setIsGuestPickerOpen(false)
  }

  const guestsMessage = formatMessage(
    {
      id: 'guest',
      defaultMessage: '{count, plural, one {Guest} other {Guests}}'
    },
    {
      count: totalValueOfGuests
    }
  )

  return (
    <>
      <FormButtonWrapper ref={guestPickerRef}>
        <FormButton
          size="md"
          id="GuestInput"
          isActive={isGuestPickerOpen}
          dataId="GuestInput"
          onClick={handleOpenGuestPicker}
          icon={
            <IconWrapper>
              <Icon name="People" />
            </IconWrapper>
          }
        >
          <ButtonContent>
            {totalValueOfGuests} {!isMobile && guestsMessage}
          </ButtonContent>
        </FormButton>
      </FormButtonWrapper>
      <Popover
        isOpen={isGuestPickerOpen}
        anchorRef={guestPickerRef}
        verticalOffset={8}
        onOutsideClick={onOutsideClick}
        placement={PopoverPlacement.BottomRight}
      >
        <WidgetGuestPicker
          guestsState={guestsState}
          onGuestsChange={onGuestsChange}
        />

        <ContentWrapper paddingX="s500" paddingBottom="s600">
          <Button variant="primary" fullWidth onClick={handleSubmit}>
            <FormattedMessageWrapper
              id="guestPicker.updateButton"
              defaultMessage="Update"
            />
          </Button>
        </ContentWrapper>
      </Popover>
    </>
  )
}
