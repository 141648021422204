// TODO (Core): Add unit tests for these functions
import {getIsProductionAppEnv} from '../../utils/environment'
import {brands} from '../config'
import {Brand, BrandCode} from '../types'

/**
 * Checks whether the passed-in brand has Cookie Consent enabled
 */
export const checkIsCookieConsentEnabledForBrand = (brand: Brand) =>
  Boolean(brand.cookieConsentId)

/**
 * Checks whether the passed-in brand is Vio
 */
export const checkIsBrandVio = (brand: Brand) => brand.code === 'vio'

/**
 * Checks whether the passed-in brand is Etrip
 */
export const checkIsBrandEtrip = (brand: Brand) => brand.code === 'etrip'

/**
 * Checks whether the passed-in brand is GoSeek
 */
export const checkIsBrandGoSeek = (brand: Brand) => brand.code === 'goseek'

/**
 * Checks whether the passed-in brand is Kiwi
 */
export const checkIsBrandKiwi = (brand: Brand) => brand.code === 'kiwi'
/**
 * Checks whether the passed-in brand is DealBase
 */
export const checkIsBrandDealbase = (brand: Brand) => brand.code === 'dealbase'

/**
 * Checks whether the brand is internal (Vio, Etrip, GoSeek)
 */
export const checkIsBrandInternal = (brand: Brand) => brand.isInternal

/**
 * Checks whether the passed-in brand is a meta-to-meta brand
 */
export const checkIsBrandOffersLockedByDefault = (brand: Brand) =>
  brand.offersLockedByDefault

/**
 * Returns the brandCode for the matching partnerKey or "vio" if no matching partnerKey is found
 */
export const getBrandCodeFromPartnerKey = (partnerKey?: string): BrandCode => {
  for (const brandCode in brands) {
    const typedBrandCode = brandCode as BrandCode

    if (brands[typedBrandCode].partnerKey === partnerKey) {
      return brands[typedBrandCode].code
    }
  }
  return brands.vio.code
}

export const getVioBrand = () => brands.vio

/**
 * Returns search app host based on brand and app environment value
 * ie: https://demo.vio.com for demo brand prod, https://tst.vio.com for vio brand staging
 */
export const getSearchHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress = brand?.address || brands.vio.address
  const stagingAddress = brand?.stagingAddress || brands.vio.stagingAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}

/**
 * Returns checkout app host based on brand and app environment value
 * ie: https://secure.demo.vio.com for demo brand prod, https://checkout.stag.bofh.fih.io for vio brand staging
 */
export const getCheckoutHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress = brand?.checkoutAddress || brands.vio.checkoutAddress
  const stagingAddress =
    brand?.stagingCheckoutAddress || brands.vio.stagingCheckoutAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}

/**
 * Returns bookings app host based on brand and app environment value
 * ie: https://my.demo.vio.com for demo brand prod, https://my.tst.vio.com for vio brand staging
 */
export const getMyBookingsHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress =
    brand?.bookingManagementAddress || brands.vio.bookingManagementAddress
  const stagingAddress =
    brand?.stagingBookingManagementAddress ||
    brands.vio.stagingBookingManagementAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}
