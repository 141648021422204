import React, {ReactNode} from 'react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Icon} from '@daedalus/atlas/Icon'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {SelectedDatesLabel} from './SelectedDatesLabel'

type FooterProps = {
  children?: ReactNode
  customMessage?: ReactNode
  disabled?: boolean
  onClick: () => void
  checkIn?: string | null
  checkOut?: string | null
  legendSlot?: ReactNode
}

const DatesWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${cssTheme.layout.spacing.s300};
  > div:only-of-type {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`

const Footer = styled('div')`
  width: 100%;
  padding: ${cssTheme.layout.spacing.s400} ${cssTheme.layout.spacing.s400};
`

export const DatePickerFooter = ({
  children,
  customMessage,
  disabled,
  onClick,
  checkIn,
  checkOut,
  legendSlot
}: FooterProps) => {
  const buttonMessage = customMessage || (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  )

  return (
    <>
      <Divider />
      <Footer data-id="SearchOverlayFooter">
        <DatesWrapper>
          {!!legendSlot && legendSlot}
          <SelectedDatesLabel checkIn={checkIn} checkOut={checkOut} />
        </DatesWrapper>
        <Button
          fullWidth
          variant={toggle<Variant[]>(
            'b4f4f3cc-website-restyle-v3',
            'primary',
            toggle<Variant[]>(
              '4728f379-vio-color-restyle',
              'primaryGradient',
              'primary'
            )
          )}
          type="submit"
          dataId="SearchButton"
          disabled={disabled}
          onClick={onClick}
          iconStart={!customMessage && <Icon name="Search" />}
        >
          <span>{buttonMessage}</span>
          <span>{children && <> · {children}</>}</span>
        </Button>
      </Footer>
    </>
  )
}
