import React, {useCallback} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'

import {Grid} from '@daedalus/atlas/helpers/Grid'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import {cssTheme} from '@daedalus/atlas/themes'

export type AgeFieldSize = Extract<SizesType, 'sm' | 'md' | 'lg'>
interface Props {
  displayName?: string
  onChange: (value: number) => void
  value: number
  size?: AgeFieldSize
}

interface AgeListItemProps {
  label: string
  value: number
}

const MAX_CHILD_AGE = 18

const InputLabelText = styled.div`
  ${cssTheme.typography.text.bodyS}
`

const InputWrapper = styled.div(
  ({size}: {size: AgeFieldSize}) => css`
    width: ${size === 'lg' ? '91px' : '62px'};
  `
)

const getAgeList = (): AgeListItemProps[] => {
  const ageList = [{label: '<1', value: 0}]

  for (let i = 1; i < MAX_CHILD_AGE; i++) {
    ageList.push({label: i.toString(), value: i})
  }

  return ageList
}

export const AgeField = ({
  displayName,
  onChange,
  value,
  size = 'lg'
}: Props) => {
  const handleChange = useCallback(
    (value: string) => {
      onChange(Number(value))
    },
    [onChange]
  )

  const ageList = getAgeList()

  return (
    <Grid container alignItems="center">
      <Grid mobileSm="fill">
        <InputLabelText>{displayName}</InputLabelText>
      </Grid>
      <Grid mobileSm="fit">
        <InputWrapper size={size}>
          <InputSelect
            value={value.toString()}
            size={size}
            onChange={handleChange}
          >
            {ageList.map((age: AgeListItemProps) => (
              <option key={age.value} value={age.value}>
                {age.label}
              </option>
            ))}
          </InputSelect>
        </InputWrapper>
      </Grid>
    </Grid>
  )
}
