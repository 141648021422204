import {Hotel} from '../../types/partnersApi/types'

export const MAXIMUM_HOTELS_TO_SHOW = 10

// Temporary solution to enhance SAPI search result
// You should use URL returned by SAPI
export const customizeRedirectUrl = ({
  url,
  rooms,
  placeId,
  checkIn,
  checkOut,
  anonymousId,
  openSearchBox,
  defaultUrl
}: {
  url?: string
  rooms?: string
  placeId?: string
  checkIn?: string
  checkOut?: string
  anonymousId?: string
  openSearchBox?: boolean
  defaultUrl?: string
}) => {
  try {
    if (url) {
      const newUrl = new URL(url)

      if (rooms) {
        newUrl.searchParams.set('rooms', rooms)
      }

      if (placeId) {
        newUrl.searchParams.set('placeId', placeId)
      }

      if (checkIn) {
        newUrl.searchParams.set('checkIn', checkIn)
      }

      if (checkOut) {
        newUrl.searchParams.set('checkOut', checkOut)
      }

      if (anonymousId) {
        newUrl.searchParams.set('anonymousId', anonymousId)
      }

      if (openSearchBox) {
        newUrl.searchParams.set('openSearchBox', '1')
      }

      return newUrl.href
    }

    if (defaultUrl) {
      const newDefaultUrlUrl = new URL(defaultUrl)
      return newDefaultUrlUrl.href
    }
    return url
  } catch {
    return url
  }
}

export const filterHotelsWithoutData = (hotels: Hotel[]): Hotel[] =>
  hotels.filter(
    hotel =>
      hotel &&
      hotel?.offers?.length &&
      hotel?.details &&
      hotel?.details?.vioUrl &&
      hotel?.details?.name &&
      hotel?.details?.starRating &&
      hotel?.details?.guestRating?.overall &&
      hotel?.details?.images?.[0] &&
      hotel?.details?.reviewCount
  )

export const getHotelsToShow = ({hotels}: {hotels?: Hotel[]}) => {
  if (!hotels) return []

  const hotelsToShowSlice = hotels.slice(0, MAXIMUM_HOTELS_TO_SHOW)
  const hotelsToShow = filterHotelsWithoutData(hotelsToShowSlice)

  return hotelsToShow
}
