import addDays from 'date-fns/addDays'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import {compose, map, reduce} from 'ramda'

import {
  dateFormat,
  dateStringToMiddayDate,
  dateToMiddayDate,
  UTS_DATE_FORMAT
} from '../utils/date'
import {DatePickerState} from './types'

/**
 * Returns the earliest valid check-in date adjusted to the midday local time (today at 12:00)
 * @returns The earliest valid check-in date
 */
export const getEarliestCheckInDate = (): Date => dateToMiddayDate(new Date())

const earliestCheckInDate = getEarliestCheckInDate()
const earliestCheckOutDate = addDays(earliestCheckInDate, 1)

export const formatUTS = (date: Date) => dateFormat(date, UTS_DATE_FORMAT)

const daysReducer = (
  hasDatesRange: boolean,
  startDate: string,
  endDate?: string
) =>
  reduce((config: {[key: string]: object}, day: string) => {
    config[day] = {
      selected: hasDatesRange,
      startingDay: day === startDate,
      endingDay: day === endDate
    }
    return config
  }, {})

export const getMarkedDays = (startDate: string, endDate?: string) => {
  const hasDatesRange = Boolean(startDate && endDate)
  const markedDaysReducer = daysReducer(hasDatesRange, startDate, endDate)

  if (hasDatesRange) {
    return compose(
      markedDaysReducer,
      map(formatUTS),
      eachDayOfInterval
    )({
      start: dateStringToMiddayDate(startDate),
      end: dateStringToMiddayDate(endDate as string)
    })
  } else {
    return markedDaysReducer([startDate])
  }
}

export const getEarliestCheckInDateString = () =>
  dateFormat(earliestCheckInDate, UTS_DATE_FORMAT)
export const getEarliestCheckOutDateString = () =>
  dateFormat(earliestCheckOutDate, UTS_DATE_FORMAT)

export const getMaxSelectableDate = (
  checkInDateString: string,
  maxLengthOfStay: number
) => {
  const checkInDate = dateStringToMiddayDate(checkInDateString)
  return compose(formatUTS, addDays)(checkInDate, maxLengthOfStay)
}
export const getCheckOutDateStringFromCheckIn = (checkInDate: Date) => {
  const nextCheckOutDate = addDays(checkInDate, 1)
  return formatUTS(nextCheckOutDate)
}

export const isSameOrBeforeDate = (
  selectedDate: string,
  dateToCompare: string
) => {
  const selectedDay = dateStringToMiddayDate(selectedDate)
  const comparableDay = dateStringToMiddayDate(dateToCompare)
  const selectedPastStartDate = isBefore(selectedDay, comparableDay)
  const selectedStartDateAgain = isSameDay(selectedDay, comparableDay)
  return selectedPastStartDate || selectedStartDateAgain
}

export const getNextDatePickerState: (
  checkIn: Date,
  checkOut?: Date | null
) => DatePickerState = (checkIn, checkOut) => {
  const nextCheckOut = checkOut ?? addDays(checkIn, 1)
  return {
    checkIn: formatUTS(checkIn),
    checkOut: formatUTS(nextCheckOut)
  }
}
