import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'

import {Button} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {BaseDatePicker as SearchDatePicker} from '@daedalus/shared/src/search/DatePicker/BaseDatePicker'

import {datePickerMessages} from './DatePicker'

const HORIIZONTAL_PICKER_MIN_WIDTH = 600
const HORIIZONTAL_PICKER_MAX_WIDTH = 802

const ContentWrapper = styled('div')`
  box-sizing: border-box;
  width: 100vw;
  min-width: ${HORIIZONTAL_PICKER_MIN_WIDTH}px;
  max-width: ${HORIIZONTAL_PICKER_MAX_WIDTH}px;
`

export const FooterWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: flex-end;
    margin: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s600}px;
  `
)

interface Props {
  isOpen: boolean
  checkIn: string
  checkOut: string
  firstDayOfWeek: number
  weekdaysShort: string[]
  containerRef: React.RefObject<HTMLDivElement>
  openedDatePickerType: DatePickerType | null
  months: string[]
  earliestCheckInDate: Date
  placement: PopoverPlacement
  verticalOffset: number
  isLoading?: boolean
  onSubmit: () => void
  onChange: (checkInDate: string, checkOutDate: string) => void
  onOutsideClick: () => void
  onDatePickerOpen: (datePickerType: DatePickerType) => void
  onCheckOutSelected?: () => void
  isCompact?: boolean
}

type FooterProps = Pick<
  Props,
  'checkIn' | 'checkOut' | 'onSubmit' | 'isLoading'
>

const Footer = ({checkIn, checkOut, isLoading, onSubmit}: FooterProps) => {
  const {formatMessage} = useIntl()

  const numberOfNights =
    checkIn && checkOut
      ? differenceInDays(parseISO(checkOut), parseISO(checkIn))
      : undefined
  const nightsMessage = formatMessage(datePickerMessages.nights, {
    count: numberOfNights
  })

  return (
    <>
      <Divider />
      <FooterWrapper>
        <Button size="md" loading={isLoading} onClick={onSubmit}>
          <FormattedMessageWrapper id="search" defaultMessage="Search" />
          {numberOfNights ? ` ${nightsMessage}` : ''}
        </Button>
      </FooterWrapper>
    </>
  )
}

export const DatePickerPopover = ({
  isOpen,
  firstDayOfWeek,
  weekdaysShort,
  containerRef,
  openedDatePickerType,
  checkIn,
  checkOut,
  months,
  earliestCheckInDate,
  placement,
  verticalOffset,
  isLoading,
  onChange,
  onOutsideClick,
  onSubmit,
  onDatePickerOpen,
  isCompact
}: Props) => (
  <Popover
    isOpen={isOpen}
    placement={placement}
    anchorRef={containerRef}
    verticalOffset={verticalOffset}
    onOutsideClick={onOutsideClick}
  >
    <ContentWrapper>
      <SearchDatePicker
        variant="horizontal"
        numberOfMonthsToShow={2}
        checkIn={checkIn}
        checkOut={checkOut}
        openedDatePickerType={openedDatePickerType}
        firstDayOfWeek={firstDayOfWeek}
        weekdaysShort={weekdaysShort}
        months={months}
        earliestCheckInDate={earliestCheckInDate}
        onChange={onChange}
        onDatePickerOpen={onDatePickerOpen}
        isCompact={isCompact}
      />
      <Footer
        checkIn={checkIn}
        checkOut={checkOut}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </ContentWrapper>
  </Popover>
)
